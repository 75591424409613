import {
  LdsSearch,
  LdsSelect,
  LdsCheckbox,
  LdsButton,
  useToastContext,
} from "@elilillyco/ux-lds-react";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_PRODUCT_LIST,
  FETCH_INDICATION_LIST,
  RESET_PRODUCTS,
  FETCH_INDICATION_LIST_BY_BRAND,
  SET_INDICATION_INITIAL_STATE,
  FETCH_EXISTING_PRODUCT_LIST,
} from "../../store/overview";
import infoToastConfig from "../shared/Toast";

import grdMapping from "./../../data/grpMapping.json";

const EditBrandIndication = ({
  brand_value,
  indication_value,
  qtr_value,
  list_id,
  department_value,
  closeBrandIndicationEdit,
  saveMetaData,
}) => {
  const dispatch = useDispatch();

  const { addToast } = useToastContext();

  const [selectedBrand, setSelectedBrand] = useState(brand_value);
  const [isBrandSelected, setIsBrandSelected] = useState(false);
  const [brandLoading, setBrandLoading] = useState(false);
  const [isBrand, setIsBrand] = useState(false);
  const [recordType, setRecordType] = useState("");
  const [selectedIndication, setSelectedIndication] =
    useState(indication_value);
  const [isIndicationSelected, setIsIndicationSelected] = useState(true);
  const [indicationLoading, setIndicationLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] =
    useState(department_value);
  const [list_quarter, setListQuarter] = useState(qtr_value);
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const { allBrandMolecules, products, indications, allIndications } =
    useSelector(({ overview }) => overview);
  const { uuid } = useSelector(({ user }) => user);

  const options = [
    {
      label: "Q1",
      value: "Q1",
    },
    {
      label: "Q2",
      value: "Q2",
    },
    {
      label: "Q3",
      value: "Q3",
    },
    {
      label: "Q4",
      value: "Q4",
    },
  ];

  useEffect(() => {
    // enable the next button once brand/molecule and indication are selected
    setIsNextDisabled(!(isBrandSelected && isIndicationSelected));
  }, [isBrandSelected, isIndicationSelected]);

  useEffect(() => {
    (async () => {
      let data = [];
      const { payload } = await dispatch(
        FETCH_PRODUCT_LIST({
          val: selectedBrand,
          department: selectedDepartment,
        })
      );

      if (Array.isArray(payload) && payload.length > 0) {
        const brandOrMolecule = payload.find(
          (item) => item.prod_nm === selectedBrand
        );
        if (brandOrMolecule) {
          setIsBrand(brandOrMolecule.record_type.toUpperCase() === "PRODUCT");
          setRecordType(brandOrMolecule.record_type.toUpperCase());
        }
      }
      setIsBrandSelected(true);
    })();
  }, []);

  const handleBrandMoleculesInput = async (e) => {
    const value = e.target.value;

    setSelectedBrand(value);

    // fetching data only when some value is provided
    if (value) {
      setBrandLoading(true);

      if (checkRoleSFMarketer()) {
        await dispatch(
          FETCH_EXISTING_PRODUCT_LIST({
            val: value,
            department: selectedDepartment,
          })
        );
        dispatch(SET_INDICATION_INITIAL_STATE());
      } else {
        await dispatch(
          FETCH_PRODUCT_LIST({ val: value, department: selectedDepartment })
        );
      }

      setBrandLoading(false);
    } else {
      setIsBrand(false);
      setIsBrandSelected(false);
      setRecordType("");
      dispatch(RESET_PRODUCTS());
    }

    setSelectedIndication("");
    setIsIndicationSelected(false);
  };

  const handleBrandMoleculesChange = (value) => {
    setSelectedBrand(value);
    setIsBrandSelected(
      allBrandMolecules.some((item) => item.prod_nm === value)
    );

    setIsBrand(
      allBrandMolecules.some(
        (item) =>
          item.prod_nm === value && item.record_type.toUpperCase() === "PRODUCT"
      )
    );
    const brandOrMolecule = allBrandMolecules.find(
      (item) => item.prod_nm === value
    );
    if (brandOrMolecule) {
      setRecordType(brandOrMolecule.record_type.toUpperCase());
    }

    if (!value) {
      setIsIndicationSelected(false);
      setSelectedIndication("");
      setRecordType("");
      dispatch(RESET_PRODUCTS());
    }
  };

  const handleIndicationChange = (value) => {
    setSelectedIndication(value);
    setIsIndicationSelected(
      allIndications.some((item) => item.indication_description === value)
    );
  };

  const handleIndicationInput = async (e) => {
    const value = e.target.value;

    setSelectedIndication(value);

    const data = {
      indication: value,
      department: selectedDepartment,
      grpPrpsCd: grdMapping[recordType],
    };

    // fetching data only when some value is provided
    if (value) {
      setIndicationLoading(true);
      if (checkRoleSFMarketer()) {
        data.brand = selectedBrand;
        await dispatch(FETCH_INDICATION_LIST_BY_BRAND(data));
      } else {
        await dispatch(FETCH_INDICATION_LIST(data));
      }
      setIndicationLoading(false);
    } else {
      setIsIndicationSelected(false);
    }
  };

  const checkRoleSFMarketer = () => {
    if (
      sessionStorage.getItem("role") === "spclty_hub_hcp_sf_marketer" ||
      sessionStorage.getItem("role") === "spclty_hub_incentivized_approver" ||
      sessionStorage.getItem("role") === "spclty_hub_admin" ||
      sessionStorage.getItem("role") === "spclty_hub_super_admin"
    ) {
      return true;
    }
    return false;
  };

  const checkOmnichannelAdvisorRole = () => {
    if (sessionStorage.getItem("role") === "spclty_hub_omnichannel_advisor") {
      return true;
    }
    return false;
  };

  const createMetaData = async () => {
    if (!isBrandSelected) {
      addToast(
        infoToastConfig(`Please Select ${isBrandOrMolecule()}.`, "error")
      );
      return;
    }

    if (!isIndicationSelected) {
      addToast(
        infoToastConfig(
          `Please Select ${isIndicationOrDiseaseState()}.`,
          "error"
        )
      );
      return;
    }

    const index = allBrandMolecules.findIndex(
      (item) => item.prod_nm == selectedBrand
    );
    const prod_id = allBrandMolecules[index].prod_id;
    const indicationIdx = allIndications.findIndex(
      (itm) => itm.indication_description === selectedIndication
    );
    const indctn_id = allIndications[indicationIdx].indctn_id;

    const metaObj = {
      prod_id: prod_id,
      indctn_id: indctn_id,
      brand: selectedBrand,
      indication: selectedIndication,
      list_quarter: list_quarter,
      updated_by: uuid,
      department_field: selectedDepartment,
    };
    saveMetaData(metaObj);
  };

  const isBrandOrMolecule = () => {
    let component = "Brand or Molecule";
    if (selectedDepartment === "MEDICAL") {
      component = "Molecule";
    }
    return component;
  };
  const isIndicationOrDiseaseState = () => {
    let component = "Indication or Disease State";
    if (selectedDepartment === "MEDICAL") {
      component = "Disease State";
    } else {
      if (isBrandSelected) {
        if (isBrand) {
          component = "Indication";
        } else {
          component = "Disease State";
        }
      }
    }
    return component;
  };

  const displaySelectionInstruction = () => {
    return `Select ${isBrandOrMolecule()}
          ${checkRoleSFMarketer() ? ", " : " and "}
          ${
            selectedDepartment === "MEDICAL"
              ? "Disease State"
              : "Indication or Disease State"
          }
          ${checkRoleSFMarketer() ? " and Quarter" : ""}.`;
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedDepartment("MEDICAL");
    } else {
      setSelectedDepartment("RETAIL");
    }

    setSelectedBrand("");
    setIsBrandSelected(false);
    setSelectedIndication("");
    setIsIndicationSelected(false);
    setIsBrand(false);
    setRecordType("");
  };

  const handleDropdownChange = ({ value }) => {
    setListQuarter(value);
  };

  return (
    <>
      <div className="mrgn-tp-6">
        <p>{displaySelectionInstruction()}</p>
        <div className="row align">
          <div className="col col-12 col-md-3">
            <span>
              Select {isBrandOrMolecule()}
              <sup>*</sup>
            </span>
            <LdsSearch
              id="selectedBrand"
              name="q-type-ahead-brand"
              inputAriaLabel={null}
              isTypeAhead={true}
              value={selectedBrand}
              onInput={handleBrandMoleculesInput}
              onChange={handleBrandMoleculesChange}
              searchSuggestions={products}
              isLoading={brandLoading}
            />
          </div>
          <div className="col col-12 col-md-3">
            <span>
              Select {isIndicationOrDiseaseState()}
              <sup>*</sup>
            </span>
            <LdsSearch
              id="SelectIndication"
              name="q-type-ahead-indication"
              inputAriaLabel={null}
              isTypeAhead={true}
              value={selectedIndication}
              onInput={handleIndicationInput}
              onChange={handleIndicationChange}
              searchSuggestions={indications}
              isLoading={indicationLoading}
              disabled={!isBrandSelected}
            />
          </div>
          {checkRoleSFMarketer() && list_quarter  && (
            <div className=" col col-md-2 d-flex align-center">
              <LdsSelect
                id="list_quarter"
                name="list_quarter"
                onChange={handleDropdownChange}
                value={qtr_value}
                options={options}
                label="Select Quarter*"
              ></LdsSelect>
            </div>
          )}
          {checkOmnichannelAdvisorRole() && (
            <div className="col col-md-2 d-flex align-center">
              <>
                <LdsCheckbox
                  id={`'department-checkbox-' + ${list_id}`}
                  required={false}
                  name={`'department-checkbox-' + ${list_id}`}
                  defaultChecked={selectedDepartment === "MEDICAL"}
                  onChange={handleCheckboxChange}
                  value={list_id}
                  label="Medical"
                ></LdsCheckbox>
              </>
            </div>
          )}
          <div
            className={`
              ${
                checkRoleSFMarketer() || checkOmnichannelAdvisorRole()
                  ? "col-md-4"
                  : "col-md-6"
              } d-flex justify-end align-center col col-12
            `}
          >
            <span>
              <LdsButton
                classes={`
                  "button-icon-right ${
                    isNextDisabled ? "" : "blue-btn"
                  } mrgn-rt"
                `}
                disabled={isNextDisabled}
                onClick={createMetaData}
              >
                Save
              </LdsButton>
              <LdsButton
                classes="button-icon-right blue-bdr-btn ml-2"
                onClick={closeBrandIndicationEdit}
              >
                Cancel
              </LdsButton>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBrandIndication;
