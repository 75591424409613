import { useParams } from "react-router-dom";
// import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import AddSpecialtyModal from "../components/details/AddSpecialtyModal";
import {
  LdsButton,
  LdsModal,
  useLdsModal,
  LdsLink,
  LdsIcon,
} from "@elilillyco/ux-lds-react";
import {
  FETCH_SPECIALITYLIST,
  FETCH_COMMENTLIST,
  FETCH_RESPONSIBILITY_LIST,
  FETCH_ADD_SPECIALTIES_LIST,
  FETCH_USER_LIST,
  FETCH_OVERVIEW_TABLE_DATA,
  FETCH_COMMENTS_DATA,
} from "../store/details";
import { useEffect, useState } from "react";
import Comments from "../components/details/Comments";
import OverviewTable from "../components/details/OverviewTable";
import { LdsLoadingSpinner } from "@elilillyco/ux-lds-react";
import "../../src/assets/scss/details/details.scss";
import CLoader from "../components/shared/CLoader";
const Details = () => {
  const userRole = sessionStorage.getItem("role") || "";
  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const [specialties, setSpecialties] = useState([]);
  const [selectedValues, setselectedValues] = useState([]);
  //visibleSpecialties contains specialties that are shown on details screen
  const [visibleSpecialties, setVisibleSpecialties] = useState({
    specialties: [],
  });
  const table = {
    thead: [
      "Specialty Code",
      "Specialty Description",
      "Incentivized",
      "Responsibility Code",
      "",
    ],
  };
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    loading,
    commentLoading,
    specialtyList,
    comments,
    responsibilityList,
    addspecialtyList,
    respCodeIndex,
    commentUserList,
    error,
  } = useSelector(({ details }) => details);

  const checkListStatus = () => {
    if (
      Object.keys(specialtyList).length &&
      specialtyList.list_status !== "APPROVED" &&
      specialtyList.list_status !== "ARCHIVE" &&
      sessionStorage.getItem("role") !== "spclty_hub_viewer"
    ) {
      return true;
    }
    // console.log("checkListStatus");
    return false;
  };
  const checkRoleSFMarketer = () => {
    if (
      sessionStorage.getItem("role") === "spclty_hub_hcp_sf_marketer" ||
      sessionStorage.getItem("role") === "spclty_hub_incentivized_approver"
    ) {
      // console.log("checkRoleSFMarketer");
      return false;
    }
    return true;
  };

  useEffect(() => {
    let tempData = JSON.parse(JSON.stringify(specialtyList));

    setVisibleSpecialties(tempData);
    setselectedValues([]);
  }, [specialtyList]);

  useEffect(() => {
    dispatch(FETCH_OVERVIEW_TABLE_DATA(id));
    dispatch(FETCH_COMMENTS_DATA(id));

    // console.log('specialtyList', specialtyList);
    // console.log('comments', comments);
    // console.log('responsibilityList', responsibilityList);
    // console.log('addspecialtyList', addspecialtyList);
    // console.log('respCodeIndex', respCodeIndex);
    // console.log('commentUserList', commentUserList);
    // console.log('error', error);
  }, []);

  useEffect(() => {
    setSpecialties(addspecialtyList);
  }, [addspecialtyList]);

  function openAddSpcltyModal() {
    setIsModalOpen(true);
  }
  const [showAddedSpecialties, setShowAddedSpecialties] = useState([]);
  function handleSelectedSpecialty(data) {
    setShowAddedSpecialties(data);
    setselectedValues([]);
  }
  return (
    <div id="main">
      <section className="section">
        <div className="wrapper details-page">
          <div>
            <div className="content-block">
              {/* <h6>Details {id}</h6> */}
              {!loading && (
                <>
                  <div>
                    <LdsLink href={`/overview?brand=${specialtyList.brand}`}>
                      <LdsIcon decorative name="CaretLeft" inline />
                      Back to Overview
                    </LdsLink>
                  </div>
                  <div className="mt-3">
                    <div>
                      <div className="row">
                        <div className="col">
                          <div>
                            <div>
                              <div className="row">
                                <div className="col-6">
                                  <h2>Details</h2>
                                </div>
                                {checkListStatus() && checkRoleSFMarketer() && (
                                  <div className="col-6 create-specialty">
                                    <div className="row-4">
                                      <span className="col-2 mrgn-rt">
                                        <LdsButton
                                          icon="Plus"
                                          iconPosition="before"
                                          classes="button-icon-left blue-btn"
                                          onClick={openAddSpcltyModal}
                                        >
                                          Add Speciality
                                        </LdsButton>
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <AddSpecialtyModal
                    isModalOpen={isModalOpen}
                    specialties={specialties}
                    setIsModalOpen={setIsModalOpen}
                    onDataUpdate={handleSelectedSpecialty}
                    visibleSpecialties={visibleSpecialties}
                    setVisibleSpecialties={setVisibleSpecialties}
                  ></AddSpecialtyModal>
                  <OverviewTable
                    bia_list={table}
                    visibleSpecialties={visibleSpecialties}
                    setVisibleSpecialties={setVisibleSpecialties}
                    responsibility_List={responsibilityList}
                    addspecialty_List={addspecialtyList}
                    showAddedSpecialties={showAddedSpecialties}
                    role={userRole}
                    selectedValues={selectedValues}
                    setselectedValues={setselectedValues}
                  />
                </>
              )}
            </div>
            <div className="content-block">
              {/* <h6>Details {id}</h6> */}
              {loading && <CLoader size={90} />}
            </div>
            <div className="content-block">
              {commentLoading ? (
                <CLoader size={90} />
              ) : (
                <Comments
                  commentList={comments}
                  specialtyList={specialtyList}
                  commentUserList={commentUserList}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Details;
